import React, { useEffect, useState, useContext } from 'react'

import PageBanner from '../featured-components/PageBanner'
import Loading from '../featured-components/Loading'

import useInfiScroll from '../../utilities/hooks/useInfiScroll'
import { MessageContext, PlayContext } from '../../utilities/context'
import { connect } from "react-redux";
import AboutMenu from '../featured-components/AboutMenu'
import { APIRequest, API_FETCH_GENRE_DETAIL_PAGE } from "../../api";
import { PLAYLIST } from '../../redux/types'

function GenreDetailPage(props) {
    const { user } = props;
    const setMessage = useContext(MessageContext)

    const [loading, setLoading] = useState(true)
    const [bannerInfo, setbannerInfo] = useState({
        album_type: '',
        name: '',
        description: '',
        user: [],
        followers: 0,
        primary_color: '#262626',
        images: [],
        release_date: ''
    })

    const [tracks, setTracks] = useState([])
    const [album, setAlbum] = useState([])
    const [uri, setUri] = useState('')
    const [setNext, lastRef] = useInfiScroll(setTracks)

    const [genreId, setGenreId] = useState(null);

    useEffect(() => {
        refreshGenrePage();
    }, [])

    const refreshGenrePage = () => {
        let id = window.location.pathname;
        setGenreId(parseInt(id.replace("/genr/", "")));

        new APIRequest.Builder()
            .get()
            .setReqId(API_FETCH_GENRE_DETAIL_PAGE)
            .setHeader(user?.token)
            .reqURL(`/elamusic/genre/${parseInt(id.replace("/genr/", ""))}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_GENRE_DETAIL_PAGE:
                setLoading(false);
                setTracks(response?.data?.genre?.singles);
                setAlbum(response?.data?.genre?.albums);
                setbannerInfo({
                    name: `${response?.data?.genre?.name}`,
                    description: '',
                    user: [],
                    followers: 0,
                    primary_color: '#262626',
                    images: response?.data?.genre?.images,
                    release_date: ''
                })
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_GENRE_DETAIL_PAGE:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };

    return (
        loading ?
            <Loading />
            :
            <div className='listPage'>
                <PageBanner pageTitle='genre' bannerInfo={bannerInfo} />
                <div className="playListContent">
                    <div className="playListOverlay" style={{ backgroundColor: `${bannerInfo.primary_color}` }}></div>
                    <div className="page-content">
                        <AboutMenu id={genreId} tracks={tracks} album={album} type="genr" title="Singles" refetchData={() => refreshGenrePage()} />
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        playlist: state.playlist
    };
};


export default connect(mapStateToProps)(GenreDetailPage);