import React, { useState, useEffect, useContext } from 'react';
import { Route } from 'react-router-dom'
import CollectionRow from '../featured-components/CollectionRow'
import { connect } from "react-redux";
import { APIRequest, API_FETCH_COLLECTION_PAGE } from "../../api";

const CollectionPage = (props) => {
    const { user } = props;
    const [artists, setArtists] = useState([])
    const [albums, setAlbums] = useState([])
    const [playlists, setPlaylists] = useState([])


    useEffect(() => {
        new APIRequest.Builder()
            .get()
            .setReqId(API_FETCH_COLLECTION_PAGE)
            .setHeader(user?.token)
            .reqURL(`/elamusic/user_like_track_list`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }, [])


    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_COLLECTION_PAGE:
                setArtists(response?.data?.followArtist);
                setPlaylists(response?.data?.likePlaylist);
                setAlbums(response?.data?.likeAlbum);
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_COLLECTION_PAGE:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };


    return (
        <div className='page-content' style={{ paddingTop: '16px' }}>
            <Route exact path='/collection/playlist'>
                <CollectionRow name='Playlists' playlists={playlists} isShow={false} type="full" />
            </Route>
            <Route exact path='/collection/artist'>
                <CollectionRow name='Artists' playlists={artists} isShow={false} type="full" />
            </Route>
            <Route exact path='/collection/album'>
                <CollectionRow name='Albums' playlists={albums} isShow={false} type="full" />
            </Route>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};


export default connect(mapStateToProps)(CollectionPage);

