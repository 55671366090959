import React, { useEffect, useState, useContext } from 'react'

import PageBanner from '../featured-components/PageBanner'
import PlayListFunctions from '../featured-components/PlayListFunctions'
import TrackList from '../featured-components/TrackList'
import Loading from '../featured-components/Loading'

import { MessageContext, PlayContext } from '../../utilities/context'
import useInfiScroll from '../../utilities/hooks/useInfiScroll'
import { connect } from "react-redux";
import { APIRequest, API_FETCH_PLAYLIST_PAGE } from "../../api";
import { PLAYLIST } from '../../redux/types'

function PlayListPage(props) {
    const { user, refreshPlaylist } = props;

    const setMessage = useContext(MessageContext)

    const [loading, setLoading] = useState(true)

    const [bannerInfo, setbannerInfo] = useState({
        name: '',
        description: '',
        user: [],
        followers: 0,
        primary_color: '#262626',
        images: [],
    })
    const [tracks, setTracks] = useState([])
    const [playlist, setPlaylist] = useState(null)
    const [like, setLike] = useState(false)
    const [setNext, lastRef] = useInfiScroll(setTracks)
    const [uri, setUri] = useState('')


    let id = window.location.pathname;
    id = parseInt(id.replace("/playlist/", ""));

    useEffect(() => {
        fetchPlaylistData();
    }, [id]);

    const fetchPlaylistData = () => {
        new APIRequest.Builder()
            .get()
            .setReqId(API_FETCH_PLAYLIST_PAGE)
            .setHeader(user?.token)
            .reqURL(`/elamusic/playlist/${id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_PLAYLIST_PAGE:
                setLoading(false);
                setTracks(response?.data?.playlist?.track_list);
                setPlaylist(response?.data?.playlist);

                let img;
                if (response?.data?.playlist?.tracks_images) {
                    img = response?.data?.playlist?.tracks_images[0] && response?.data?.playlist?.tracks_images[0].images
                }
                setbannerInfo({
                    name: response?.data?.playlist?.title,
                    description: '',
                    user: user?.user,
                    followers: 0,
                    primary_color: '#262626',
                    images: img,
                })
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_PLAYLIST_PAGE:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };


    return (
        <>
            {loading ?
                <Loading />
                :
                <div className='listPage'>
                    <PageBanner pageTitle='playlist' bannerInfo={bannerInfo} type="playlist" />
                    <div className="playListContent">
                        <div className="playListOverlay" style={{ backgroundColor: `${bannerInfo.primary_color}` }}></div>
                        <PlayListFunctions follow={like} setMessage={setMessage} type="playlist" playlistData={playlist} refreshPlaylist={refreshPlaylist} refetchData={() => fetchPlaylistData()} tracks={tracks} />
                        <div className="page-content">
                            <TrackList ref={lastRef} tracks={tracks} type="playlist" refetchData={() => fetchPlaylistData()} id={playlist?.id} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        playlist: state.playlist
    };
};

export default connect(mapStateToProps)(PlayListPage);
