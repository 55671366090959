import React from 'react'
import PlayCard from './PlayCard'

export default function RowGrid({ type, info }) {
    return (
        <div className="RowGrid">
            {info && info.map((item) => {
                return <PlayCard key={item?.id} id={item?.id} info={item} type={type} />
            })}
        </div>
    )
}
