import React, { useContext, useState, useRef } from 'react';
import Icon from '../icons'
import { LoginContext, MessageContext } from '../../utilities/context'
import { Col, Row, Form, Button, Modal } from 'react-bootstrap'
import { APIRequest, API_ADD_PLAYLIST } from "../../api";
import { connect } from "react-redux";
import { Notification } from '../notification';

function CreatePlaylist(props) {
    const { refreshPlaylist, user } = props;
    const setMessage = useContext(MessageContext)
    const formRef = useRef(null);

    const [visible, setVisible] = useState(false);

    const addPlaylist = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries());

        new APIRequest.Builder()
            .post()
            .setReqId(API_ADD_PLAYLIST)
            .setHeader(user?.token)
            .jsonParams(formDataObj)
            .reqURL(`/elamusic/playlist/add`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_ADD_PLAYLIST:
                refreshPlaylist();
                setVisible(false);
                formRef.current.reset();
                Notification("success", response?.meta?.message);
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_ADD_PLAYLIST:
                console.log(response, "error");
                Notification("danger", response?.meta?.message);
                break;
            default:
                break;
        }
    };

    const handleClose = () => {
        setVisible(false);
        formRef.current.reset();
    }

    return (
        <>
            {user?.loggedIn ?
                <button className="create-button no-outline" onClick={() => setVisible(true)}>
                    <div className="playlist-icon">
                        <Icon name='Create' />
                    </div>
                    <span className="featured-label">Create Playlist</span>
                </button>
                :
                <button className="create-button no-outline" data-tip='create' data-for='tooltip' data-event='click' onClick={() => setMessage('Oops, it look like I chose not to implement this feature :)')}>
                    <div className="playlist-icon">
                        <Icon name='Create' />
                    </div>
                    <span className="featured-label">Create Playlist</span>
                </button>
            }



            <Modal show={visible} onHide={handleClose} centered backdrop={false} contentClassName="bg_theme_color" className="bg_black close_btn">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: '#222326' }}>
                        Add Playlist
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={addPlaylist} ref={formRef}>
                        <Row>
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label className="input_label" style={{ color: 'black', fontWeight: '300' }}>Playlist Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Playlist Name" name="title" required />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Button type="submit" block className="bg-btn border-0 mt-1">
                                    Add Playlist
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(CreatePlaylist);
