import React from 'react';
import { Route } from 'react-router-dom'

import AboutNavItem from './AboutNavItem'
import RowGrid from './RowGrid'
import ArtistRow from './ArtistRow'

const AboutMenu = ({ id, tracks, album, type, playContextTrack, refetchData, title }) => {
    return (
        <>
            <div style={{ paddingTop: '1.5em', position: "relative" }}>
                <Route exact path={`/${type}/${id}`}>
                    <ArtistRow title={title} display='list' list={tracks} playContextTrack={playContextTrack} type={type} refetchData={refetchData} id={id} />
                    <ArtistRow title='Albums' display='grid' list={album} type={'album'} id={id} />
                </Route>
            </div>
        </>
    );
}



export default AboutMenu;
