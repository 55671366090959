import React from 'react'
import TrackListItem from './TrackListItem'

const TrackList = React.forwardRef(({ tracks, styleName, highlight, playContextTrack, refetchData, id, type }, ref) => {
    return (
        <div className="trackListContainer">
            <ol className="trackList">
                {tracks && tracks.map((track, index) => {
                    return <TrackListItem track={track} index={index} key={track.id} styleName={styleName} highlight={track.id === highlight} playContextTrack={playContextTrack} type={type} refetchData={refetchData} id={id} allTrack={tracks} />
                })}
            </ol>
        </div>
    )
})


export default TrackList