export const REGISTER = 'register';
export const AUTHENTICATE = 'authenticate';
export const DEAUTHENTICATE = 'deauthenticate';
export const USER = 'user';
export const USERDATA = 'userData';
export const LOGGEDIN = 'loggedIN';
export const ISPLAY = 'isPlay';
export const PLAYLIST = 'playlist';
export const PLAYINDEX = 'playIndex';
export const PLAYID = 'playId';
