import { APIRequest } from "./api-request";

export const API_LOGIN_SUBMIT = 1;
export const API_SIGNUP_SUBMIT = 2;
export const API_FETCH_PROFILE = 3;
export const API_PROFILE_SUBMIT = 4;
export const API_CHANGE_PASSWORD_SUBMIT = 5;
export const API_CHANGE_PROFILE = 6;
export const API_FETCH_HOME_PAGE = 7;
export const API_FETCH_GENRE_PAGE = 8;
export const API_FETCH_ARTIST_PAGE = 9;
export const API_FETCH_ALBUM_PAGE = 10;
export const API_FETCH_GENRE_DETAIL_PAGE = 11;
export const API_FETCH_COLLECTION_PAGE = 12;
export const API_FETCH_LIKE_PAGE = 13;
export const API_SEARCH_PAGE = 14;
export const API_FETCH_PLAYLIST = 15;
export const API_FETCH_PLAYLIST_PAGE = 16;
export const API_ADD_PLAYLIST = 17;
export const API_DELETE_PLAYLIST = 18;
export const API_EDIT_PLAYLIST = 19;
export const API_FOLLOW_ARTIST = 20;
export const API_TRACK_LIKE_PAGE = 21;
export const API_LIKE_ALBUM = 22;
export const API_ADD_TRACK_TO_PLAYLIST = 23;
export const API_REMOVE_TRACK_TO_PLAYLIST = 24;
export const API_GET_COUNTRY = 25;
export const API_GET_CITY = 26;
export const API_LOGOUT = 27;
export const API_PLAY_CARD = 28;

export { APIRequest };