import React, { useContext } from 'react'
import MusicImg from '../../images/Place_Holder_Image.png'

export default function CardDisplay({ url, type }) {
    return (
        <>
            <div className="CardDisplay" style={{ borderRadius: type === 'Artist' ? '50%' : '0' }}>
                <img src={url ? url : MusicImg} loading='lazy' className='previewImg' style={{ borderRadius: type === 'Artist' ? '50%' : '0' }} alt='' />
            </div>
        </>
    )
}

