import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'


function RowTitle(props) {
    const { title, id, isShow, user } = props;
    return (
        <div className="RowTitle">
            <h1 style={{
                fontSize: '24px',
                lineHeight: '28px',
                letterSpacing: '-0.04em',
                fontWeight: '700',
                textTransform: 'capitalize',
                color: 'white'
            }}>{title.replaceAll("_", " ")}</h1>
            {
                (user?.loggedIn && isShow) && <Link to={`/genre/${id}`} className='seeAll'>see all</Link>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(RowTitle);
