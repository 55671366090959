import React, { useContext } from 'react';
import Icon from '../icons'
import CardDisplay from './CardDisplay';
import CardInfo from './CardInfo';
import { Link } from 'react-router-dom'
import { PlayContext } from '../../utilities/context';
import { connect } from 'react-redux'


const ArtistRowItem = React.forwardRef((props, ref) => {
    const loggedIn = props?.user?.loggedIn
    const { info } = props;
    const { title, type, id, images } = info

    const url = () => {
        return `/${type?.toLowerCase()}/${id}`
    }

    const updatePlayer = useContext(PlayContext)
    const playContext = () => {
        // if (uri) {
        //     var body
        //     if (type === 'track') {
        //         body = {
        //             uris: [uri]
        //         }
        //     } else {
        //         body = {
        //             context_uri: uri
        //         }
        //     }
        //     const request = putWithToken(`https://api.spotify.com/v1/me/player/play`, token, source, body)
        //     request()
        //         .then(response => {
        //             if (response.status === 204) {
        //                 setTimeout(() => updatePlayer(), 1000)
        //             } else {
        //                 setMessage(`ERROR: ${response}`)
        //             }
        //         })
        //         .catch(error => setMessage(`ERROR: ${error}`))
        // } else {
        //     history.push(`/tracks`)
        // }
    }

    return (
        <>
            <div className='pcWrapper'>
                <Link to={url()} style={{ textDecoration: 'none', color: 'var(--main-text)', zIndex: '3' }}>
                    <div ref={ref} className="PlayCard">
                        <CardDisplay url={images[0]?.url} type={info?.type} />
                        <CardInfo title={title} />
                    </div>
                </Link>
                {
                    loggedIn ?
                        <button className="smallButton no-outline" title="Play" onClick={() => {
                            playContext()
                            updatePlayer()
                        }}>
                            <Icon name="Play" height='17' width='17' />
                        </button>
                        :
                        <button className="smallButton no-outline" title="Play" data-tip='play' data-for='tooltipMain' data-event='click'>
                            <Icon name="Play" height='17' width='17' />
                        </button>
                }
            </div>
        </>
    );
})

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(ArtistRowItem);

