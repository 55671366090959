import React from 'react';
import ArtistRowTitle from './ArtistRowTitle'
import TrackList from './TrackList'
import ArtistRowGrid from './ArtistRowGrid'
import RowTitle from './RowTitle';

const ArtistRow = ({ title, display, list, playContextTrack, type,refetchData,id }) => {
    if (list && list.length > 0) {
        return (
            <div>
                <RowTitle title={title} id={type} isShow={false} id={id}/>
                {/* <ArtistRowTitle title={title}/> */}
                {display === 'list' ?
                    <TrackList tracks={list} styleName='simplify' playContextTrack={playContextTrack} type={type} refetchData={refetchData} id={id}/> :
                    <ArtistRowGrid list={list} type={type} id={id}/>}
            </div>
        );
    } else {
        return null
    }
}


export default ArtistRow;
