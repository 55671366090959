import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import LOGO from '../../images/wmt-logo.png';
import { APIRequest, API_LOGIN_SUBMIT } from '../../api';
import { connect } from "react-redux";
import { AUTHENTICATE, USER } from '../../redux/types';
import { authenticate, getUser, isLoggedIn } from '../../redux/actions/authAction';
import { Notification } from '../notification';

function Login(props) {
    const formRef = useRef(null);
    const emailFormRef = useRef(null);
    const history = useHistory();

    const [validated, setValidated] = useState(false);
    const [emailValidatedForm, setEmailValidatedForm] = useState(false);
    const [layout, setLayout] = useState("email");
    const [visible, setVisible] = useState(false);
    const [isForgetPin, setIsForgetPin] = useState(false);

    const submit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            const formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries());
            setVisible(true);
            setLayout("pin");
        }
        setValidated(true);
    };


    const emailFormSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            const formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries());

            new APIRequest.Builder()
                .post()
                .setReqId(API_LOGIN_SUBMIT)
                .jsonParams(formDataObj)
                .reqURL("/signin/email")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        }
        setEmailValidatedForm(true);
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_LOGIN_SUBMIT:
                props.authenticate(response.data.user.token);
                props.getUser(response.data.user);
                props.isLoggedIn(true);
                Notification("success", response?.meta?.message);
                window.location.href = '/'
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_LOGIN_SUBMIT:
                Notification("danger", response?.meta?.message);
                break;
            default:
                break;
        }
    }

    const handleClose = () => {
        setIsForgetPin(false);
        setLayout("main");
        setValidated(false);
    }

    useEffect(() => {
        window.addEventListener("popstate", () => {
            history.go(1);
        });
    }, [])

    return (
        <>
            <Row className="min-vh-100 d-flex align-items-center justify-content-center" noGutters style={{ backgroundColor: '#040404' }}>
                <Col lg={6} className="d-flex justify-content-end align-items-center">
                    <div style={{ height: "200px", with: 'auto' }}>
                        <a href='/'>
                            <img src={LOGO} height="100%" width="auto" />
                        </a>
                    </div>
                </Col>
                <Col sm={12} lg={6}>
                    {
                        layout === "main" &&
                        <Row className="mt-3 d-flex justify-content-center" noGutters>
                            <Col lg={7} sm={10}>
                                <Button
                                    className="w-100 mt-3 modify_btn secondary-btn"
                                    onClick={() => setLayout("phone")}
                                >
                                    Sign In With Phone
                                </Button>
                                <Button
                                    className="w-100 mt-3 modify_btn secondary-btn"
                                    onClick={() => setLayout("email")}
                                >
                                    Sign In With Email
                                </Button>
                            </Col>
                        </Row>
                    }

                    {
                        layout === "email" &&
                        <Row className="d-flex justify-content-center" noGutters>
                            <Col lg={7} sm={10}>
                                <Form noValidate validated={emailValidatedForm} onSubmit={emailFormSubmit} ref={emailFormRef}>
                                    <Form.Group>
                                        <Form.Label className="input_label">Email</Form.Label>
                                        <Form.Control name="email" type="email" placeholder="Email" required />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter Valid Email
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="input_label">Password</Form.Label>
                                        <Form.Control name="password" type="password" placeholder="Password" required />
                                        <Form.Control.Feedback type="invalid">
                                            Password is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button type="submit" block className="modify_btn primary-btn mt-4">
                                        Sign In
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    }

                    <Row className="mt-3 d-flex justify-content-center" noGutters>
                        <Col lg={7} sm={10}>
                            <hr style={{ border: '1px solid #616467' }} />
                            <h4 className="text_center input_label">Don't have an account?</h4>
                            <Button
                                className="w-100 mt-3 modify_btn secondary-btn"
                                onClick={() => history.push('/signup')}
                            >
                                Sign up With Music
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => ({
    authenticate: (token) => dispatch(authenticate(token)),
    getUser: (user) => dispatch(getUser(user)),
    isLoggedIn: (data) => dispatch(isLoggedIn(data))
})


export default connect(mapStateToProps, mapDispatchToProps)(Login);