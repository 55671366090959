import React, { useContext } from "react";
import Icon from "../icons";
import msTimeFormat from "../../utilities/utils";
import { PlayContext, PlayListContext } from "../../utilities/context";
import { Dropdown } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Notification } from "../notification";
import { APIRequest, API_TRACK_LIKE_PAGE, API_ADD_TRACK_TO_PLAYLIST, API_REMOVE_TRACK_TO_PLAYLIST } from "../../api";

const TrackListItem = React.forwardRef(
	(props, ref) => {
		const { track, styleName, highlight, type, user, refetchData, id, allTrack, index } = props;
		const { uri, title, images, track_time, track_artist, is_like } = track;

		const playList = useContext(PlayListContext);

		const setPlay = useContext(PlayContext);

		let thumbNail = images[0] ? images[0]?.url : null;

		const formattedTime = msTimeFormat(track_time);

		const addTrackPlaylist = (playlistId) => {
			new APIRequest.Builder()
				.post()
				.setReqId(API_ADD_TRACK_TO_PLAYLIST)
				.setHeader(user?.token)
				.jsonParams({
					"playlist_id": playlistId,
					"track_ids": [track?.id]
				})
				.reqURL(`/elamusic/playlist/tracks`)
				.response(onResponse)
				.error(onError)
				.build()
				.doRequest();
		}

		const removeTrackPlaylist = () => {
			new APIRequest.Builder()
				.post()
				.setReqId(API_REMOVE_TRACK_TO_PLAYLIST)
				.setHeader(user?.token)
				.jsonParams({
					"playlist_id": id,
					"track_ids": [track?.id]
				})
				.reqURL(`/elamusic/remove/playlist/tracks`)
				.response(onResponse)
				.error(onError)
				.build()
				.doRequest();
		}

		const isLike = () => {
			new APIRequest.Builder()
				.post()
				.setReqId(API_TRACK_LIKE_PAGE)
				.setHeader(user?.token)
				.jsonParams({
					"id": track?.id,
					"type": 2
				})
				.reqURL(`/elamusic/like/playlist-album-track`)
				.response(onResponse)
				.error(onError)
				.build()
				.doRequest();
		}

		const onResponse = (response, reqId) => {
			switch (reqId) {
				case API_TRACK_LIKE_PAGE:
					refetchData && refetchData();
					break;
				case API_ADD_TRACK_TO_PLAYLIST:
					refetchData && refetchData();
					Notification("success", "Added to Playlist");
					break;
				case API_REMOVE_TRACK_TO_PLAYLIST:
					refetchData && refetchData();
					break;
				default:
					break;
			}
		};

		const onError = (response, reqId) => {
			switch (reqId) {
				case API_TRACK_LIKE_PAGE:
					console.log(response, "error");
					break;
				case API_ADD_TRACK_TO_PLAYLIST:
					console.log(response, "error");
					break;
				case API_REMOVE_TRACK_TO_PLAYLIST:
					console.log(response, "error");
					break;
				default:
					break;
			}
		};

		return (
			<li
				ref={ref}
				className={`trackListItem ${track?.id === props.playlist?.playId ? "highlight" : null}`}
			>
				<div
					className="trackItemPlay"
					style={styleName === "simplify" ? simplyStyle : null}
				>
					<button
						className={
							styleName === "simplify"
								? "hoverIcon no-outline"
								: "hoverIcon trackTopAlign no-outline"
						}
						onClick={() => {
							setPlay(allTrack, index, track?.id);
						}}
					>
						<Icon name="Play" height="20" width="20" />
					</button>
					<div
						className={
							styleName === "simplify" ? "itemIcon" : "itemIcon trackTopAlign"
						}
						style={{ marginTop: styleName === "simplify" ? "0" : null }}
					>
						<Icon name="Music" />
					</div>
				</div>


				<div className="trackMidAlign">
					<div className="trackItemThumb">
						{thumbNail ? (
							<img
								loading="lazy"
								src={thumbNail}
								style={{ width: "100%", height: "100%" }}
								alt=""
							/>
						) : (
							<div
								style={{
									position: "absolute",
									top: "35%",
									bottom: "35%",
									left: "35%",
									right: "35%",
								}}
							>
								<Icon name="Music2" />
							</div>
						)}
					</div>
				</div>


				<div className="trackItemInfo">
					<div
						className={
							styleName === "simplify" ? "trackMidAlign" : "trackTopAlign"
						}
					>
						<div className="trackName ellipsis-one-line">{title}</div>


						<div className="trackInfo">
							<span className="trackArtists ellipsis-one-line">
								{track_artist && track_artist.map((e) => (
									<Link to={`/artist/${e?.artist.id}`} key={e?.artist.id}>
										{`${e?.artist?.firstname} ${e?.artist?.lastname}`}
									</Link>
								))}
							</span>
						</div>

					</div>
				</div>

				<div className="mr-3 mt-1">
					<div
						className={`duration ${styleName === "simplify" ? "trackMidAlign" : "trackMidAlign"}`}
					>
						<span>{formattedTime}</span>
					</div>
				</div>
				<div className="mr-3 d-flex align-items-center" onClick={() => isLike()} >
					<Icon name='Heart' fill={is_like} />
				</div>
				<div className="d-flex align-items-center">
					<Dropdown>
						<Dropdown.Toggle id="dropdown-basic" className="playlist_dropdown">
							• • •
						</Dropdown.Toggle>

						<Dropdown.Menu className="menu_item">
							{
								(type === "playlist") &&
								<Dropdown.Item onClick={() => removeTrackPlaylist()}>Remove from this playlist</Dropdown.Item>
							}
							<Dropdown alignRight>
								<Dropdown.Toggle id="dropdown-basic1" className="playlist_dropdown" style={{ padding: '6px 20px' }}>
									Add to Playlist
								</Dropdown.Toggle>

								<Dropdown.Menu className="menu_item" alignRight={true}>
									{
										playList && playList.map((e, index) => <Dropdown.Item key={e?.id} onClick={() => addTrackPlaylist(e?.id)}>{e?.title}</Dropdown.Item>)
									}
								</Dropdown.Menu>
							</Dropdown>
							{/* <Dropdown.Item>Download</Dropdown.Item> */}
							{/* <Dropdown.Item>Share</Dropdown.Item> */}
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</li>
		);
	}
);

const simplyStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
		playlist: state.playlist
	};
};

export default connect(mapStateToProps)(TrackListItem);

