import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import LOGO from '../../images/wmt-logo.png';
import { APIRequest, API_SIGNUP_SUBMIT, API_GET_COUNTRY, API_GET_CITY } from '../../api';
import { Notification } from '../notification';
import { connect } from "react-redux";
import Loader from 'react-loader-spinner'

function SignUp(props) {
    const formRef = useRef(null);
    const emailFormRef = useRef(null);
    const history = useHistory();

    const [layout, setLayout] = useState("email");
    const [emailValidatedForm, setEmailValidatedForm] = useState(false);
    const [isPasswordForm, setIsPasswordForm] = useState(false);
    const [inputValue, setInputValue] = useState({});
    const [errors, setErrors] = useState({});
    const [isEmailVerification, setIsEmailVerification] = useState(false);
    const [emailData, setEmailData] = useState(null);
    const [country, setCountry] = useState([]);
    const [city, setCity] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        let input = inputValue;
        input[event.target.name] = event.target.value;
        setInputValue(input);
    }

    useEffect(() => {
        new APIRequest.Builder()
            .get()
            .setReqId(API_GET_COUNTRY)
            .reqURL("/content_list")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();

        window.addEventListener("popstate", () => {
            history.go(1);
        });
    }, [])

    const handleCountry = (e) => {
        new APIRequest.Builder()
            .post()
            .setReqId(API_GET_CITY)
            .jsonParams({
                "country_id": e.target.value
            })
            .reqURL("/city")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const validate = () => {
        let input = inputValue;
        let errors = {};
        let isValid = true;

        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
        }

        if (!input["confirm_password"]) {
            isValid = false;
            errors["confirm_password"] = "Please enter your confirm password.";
        }

        if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
            if (input["password"] != input["confirm_password"]) {
                isValid = false;
                errors["confirm_password"] = "Passwords not match.";
            }
        }

        setErrors(errors);
        return isValid;
    }

    const emailFormSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            const formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries());
            setIsPasswordForm(true);
            setLayout("password");
            setEmailData(formDataObj);
        }
        setEmailValidatedForm(true);
    }

    const passwordFormSubmit = (event) => {
        event.preventDefault();

        if (validate()) {
            const formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries());
            let data = { ...emailData, ...formDataObj }

            setLoading(true);

            new APIRequest.Builder()
                .post()
                .setReqId(API_SIGNUP_SUBMIT)
                .jsonParams(data)
                .reqURL("/signup/email")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        }
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_SIGNUP_SUBMIT:
                setIsEmailVerification(true);
                setLayout("email");
                setEmailValidatedForm(false);
                setLoading(false);
                Notification("success", response?.meta?.message)
                history.push('/login')
                break;
            case API_GET_COUNTRY:
                setCountry(response?.data?.country);
                break;
            case API_GET_CITY:
                setCity(response?.data?.city);
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_SIGNUP_SUBMIT:
                Notification("danger", response?.meta?.message);
                break;
            case API_GET_COUNTRY:
                console.log(response, "error");
                break;
            case API_GET_CITY:
                console.log(response, "error");
                break;
            default:
                break;
        }
    }

    return (
        <Row className="min-vh-100 d-flex align-items-center justify-content-center" noGutters style={{ backgroundColor: '#040404' }}>
            <Col lg={6} className="d-flex justify-content-end align-items-center">
                <div style={{ height: "200px", with: 'auto' }}>
                    <a href='/'>
                        <img src={LOGO} height="100%" width="auto" />
                    </a>
                </div>
            </Col>
            <Col sm={12} lg={6}>
                {
                    layout === "email" &&
                    <Row className="d-flex justify-content-center mt-2" noGutters>
                        <Col lg={7} sm={10}>
                            <Form noValidate validated={emailValidatedForm} onSubmit={emailFormSubmit} ref={emailFormRef}>
                                <Form.Group>
                                    <Form.Label className="input_label">Email</Form.Label>
                                    <Form.Control name="email" type="email" placeholder="Email" required />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid Email
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="input_label">Country</Form.Label>
                                    <Form.Control name="country_id" as="select" placeholder="Select Country" required onChange={handleCountry}>
                                        <option value="" style={{ display: 'none' }}>Country</option>
                                        {
                                            (country && country.length > 0) && country.map(e => <option value={e?.id}>{e?.name}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Country is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="input_label">City</Form.Label>
                                    <Form.Control name="city_id" as="select" placeholder="Select City" required>
                                        <option value="" style={{ display: 'none' }}>City</option>
                                        {
                                            (city && city.length > 0) && city.map(e => <option value={e?.id}>{e?.name}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        City is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="input_label">Age</Form.Label>
                                    <Form.Control name="age" type="text" placeholder="Age (e.g.:35)" required />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Age
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button variant="primary" type="submit" block className="modify_btn primary-btn mt-4">
                                    Sign Up
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                }

                {
                    (isPasswordForm && layout === "password") ?
                        loading ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Loader
                                    type='ThreeDots'
                                    color='#fff'
                                />
                            </div>
                            :
                            <Row className="d-flex justify-content-center" noGutters>
                                <Col lg={7} sm={10}>
                                    <Form onSubmit={passwordFormSubmit}>
                                        <Row>
                                            <Col className="px-4 py-2">
                                                <Form.Group>
                                                    <Form.Label className="input_label">Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Password" name="password" onChange={handleChange} />
                                                    <div className="text-danger text_small">{errors.password}</div>
                                                </Form.Group>

                                                <Form.Group>
                                                    <Form.Label className="input_label">Confirm Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Confirm Password" name="confirm_password" onChange={handleChange} />
                                                    <div className="text-danger text_small">{errors.confirm_password}</div>
                                                </Form.Group>

                                                <Button type="submit" block className="modify_btn primary-btn mt-4">
                                                    {`I'm New ( Register )`}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row> : null
                }

                <Modal show={isEmailVerification} onHide={() => setIsEmailVerification(false)} centered backdrop={false} contentClassName="bg_theme_color" className="bg_black close_btn">
                    <Modal.Header closeButton>
                        <Modal.Title className="input_label" style={{ color: '#222326' }}>Email Verification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={12} style={{ fontSize: '15px', color: '#222326' }}>
                                <div>
                                    We Have send an email for verification.Please verify your email and then login.
                                </div>
                            </Col>
                            <Col lg={12} className="mt-3">
                                <Button block className="border-0 modify_btn primary-btn"
                                    onClick={() => {
                                        setIsEmailVerification(false);
                                        setIsPasswordForm(false);
                                    }}
                                >
                                    Ok
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Row className="mt-3 d-flex justify-content-center mb-2" noGutters>
                    <Col lg={7} sm={10}>
                        <hr style={{ border: '1px solid #616467' }} />
                        <h4 className="text_center input_label">Have an account?</h4>
                        <Button
                            className="w-100 mt-3 modify_btn secondary-btn"
                            onClick={() => {
                                setEmailValidatedForm(false);
                                history.push('/login')
                            }}
                        >
                            Sign In With Music
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(SignUp);
