import React from 'react'
import RowTitle from './RowTitle'
import RowGrid from './RowGrid'

const CollectionRow = React.forwardRef(({ name, playlists, id, isShow, type }, ref) => {
    return (
        <div className="CollectionRow">
            <RowTitle title={name} id={id} isShow={isShow} />
            <RowGrid ref={ref} playlists={playlists} type={type} />
        </div>
    )
})

export default CollectionRow