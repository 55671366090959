import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { APIRequest, API_FETCH_GENRE_PAGE } from "../../api";
import { MessageContext } from "../../utilities/context";
import useInfiScroll from "../../utilities/hooks/useInfiScroll";
import PageTitle from "../featured-components/PageTitle";
import PlayCard from "../featured-components/PlayCard";

function GenrePage(props) {
    const { user } = props;
    const setMessage = useContext(MessageContext);

    const [playLists, setPlayLists] = useState([]);
    const [name, setName] = useState("");

    const [setNext, lastRef] = useInfiScroll(setPlayLists);

    const pathName = window.location.pathname.replace("/genre/", "");

    useEffect(() => {
        refreshGenrePage();
    }, []);

    const refreshGenrePage = () => {
        let url;
        if (pathName === "album") {
            url = `/elamusic/new_release/Album`;
        } else if (pathName === "track") {
            url = `/elamusic/new_release/Track`;
        } else if (pathName === "artist") {
            url = `/elamusic/hotArtist`;
        } else {
            url = `/elamusic/${pathName}`;
        }

        new APIRequest.Builder()
            .get()
            .setReqId(API_FETCH_GENRE_PAGE)
            .setHeader(user?.token)
            .reqURL(url)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_GENRE_PAGE:
                setPlayLists(response?.data?.data);
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_GENRE_PAGE:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };

    const Title = () => {
        if (pathName === 'album') {
            return 'New Release Albums';
        } else if (pathName === 'track') {
            return 'New Release Singles';
        } else if (pathName === 'artist') {
            return 'Whats Hot';
        } else if (pathName === 'genre') {
            return 'Genre';
        }
    }

    return (
        <div className="GenrePage page-content">
            <PageTitle name={Title()} />
            <div className="browseGrid">
                {playLists &&
                    playLists.map((playlist, index) => (
                        <PlayCard ref={lastRef} key={playlist.id} info={playlist} id={playlist?.id} type={playlist?.type} index={index} />
                    ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(GenrePage);
