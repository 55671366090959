import React from 'react';
import CreatePLaylist from './CreatePlaylist.js'



//Featured playlist containing 2 main components - the button to make a new playlist and a featured item (the liked songs playlist)
function FeaturedPlaylist(props) {
    const { refreshPlaylist } = props
    return (
        <>
            <div className="featured-playlists">
                <CreatePLaylist refreshPlaylist={refreshPlaylist} />
                {props.children}
            </div>
        </>
    );
}


export default FeaturedPlaylist;
