import React, { useEffect, useState } from 'react'

import SearchRowTitle from './SearchRowTitle'
import SearchRowGrid from './SearchRowGrid'

export default function SearchRow({ title, type, result }) {
    return (
        <div className='CollectionRow' style={{ display: result && result.length === 0 ? 'none' : 'grid' }}>
            <SearchRowTitle title={title} />
            <SearchRowGrid type={type} info={result} />
        </div>
    )
}
