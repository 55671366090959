import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'

import PageBanner from '../featured-components/PageBanner'
import PlayListFunctions from '../featured-components/PlayListFunctions'
import CollectionRow from '../featured-components/CollectionRow'
import Loading from '../featured-components/Loading'

import useId from '../../utilities/hooks/useId'
import useInfiScroll from '../../utilities/hooks/useInfiScroll'
import { UserContext, MessageContext } from '../../utilities/context'

export default function UserPage({ query, setMessage }) {
    const id = useId()
    const user = useContext(UserContext)
    const setStatusMessage = useContext(MessageContext)
    const [loading, setLoading] = useState(true)

    const [bannerInfo, setbannerInfo] = useState({
        name: '',
        description: '',
        user: [],
        followers: 0,
        primary_color: 'rgb(83, 83, 83)',
        images: [],
        total: 0
    })

    const [playLists, setplayLists] = useState([])
    const [setNext, lastRef] = useInfiScroll(setplayLists)
    const [follow, setFollow] = useState(false)
    const source = axios.CancelToken.source()

    return (
        loading ?
            <Loading />
            :
            <div className='listPage' style={{ display: playLists && playLists.length === 0 ? 'none' : 'block' }}>
                <PageBanner pageTitle='profile' bannerInfo={bannerInfo} />
                <div className="playListContent">
                    <div className="playListOverlay" style={{ backgroundColor: `${bannerInfo.primary_color}` }}></div>
                    <PlayListFunctions type={id === user.id ? 'none' : 'user'} follow={follow} setMessage={setStatusMessage} />
                    <div className="page-content" style={{ marginTop: '40px' }}>
                        <CollectionRow ref={lastRef} name='Public Playlists' id={null} playlists={playLists} />
                    </div>
                </div>
            </div>
    )
}



