import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import HomePage from '../pages-components/HomePage'
import SearchPage from '../pages-components/SearchPage'
import GenrePage from '../pages-components/GenrePage'
import PlayListPage from '../pages-components/PlayListPage'
import AlbumPage from '../pages-components/AlbumPage'
import UserPage from '../pages-components/UserPage'
import ArtistPage from '../pages-components/ArtistPage'
import CollectionPage from '../pages-components/CollectionPage'
import LikePage from '../pages-components/LikePage'

import ReactToolTip from 'react-tooltip'
import generateContent from '../../utilities/TipContent'
import Profile from '../pages-components/Profile'
import GenreDetailPage from '../pages-components/GenreDetailPage'
import { connect } from "react-redux";

function PageContent(props) {
    const { query, playlists, refreshPlaylist, message, status, user } = props;

    return (
        <>
            <Switch>
                <Route path='/' exact>
                    <HomePage />
                </Route>
                <Route path={`/search`}>
                    <SearchPage query={query} />
                </Route>
                <Route path='/genre'>
                    <GenrePage />
                </Route>
                <Route path='/genr/:id' exact>
                    <GenreDetailPage />
                </Route>
                <Route path='/playlist/:id'>
                    <PlayListPage playlists={playlists} refreshPlaylist={refreshPlaylist} />
                </Route>
                <Route path='/album' >
                    <AlbumPage />
                </Route>
                <Route path='/user' >
                    <UserPage />
                </Route>
                <Route path='/artist' >
                    <ArtistPage />
                </Route>
                <Route path='/collection'>
                    {/* <Redirect to='/collection/playlist' /> */}
                    <CollectionPage />
                </Route>
                <Route path='/tracks'>
                    <LikePage />
                </Route>
                <Route path='/profile'>
                    <Profile />
                </Route>
            </Switch>
            <div className={`status-bar-wrapper ${status ? 'active' : ''}`}>
                <div className={`status-bar ${status ? 'active' : ''}`}>{message}</div>
            </div>
            <ReactToolTip className='toolTip ttMain' id='tooltipMain' disable={user?.loggedIn} place='bottom' effect='solid' backgroundColor='var(--tip-content)' globalEventOff='click' getContent={dataTip => generateContent(dataTip)} clickable={true} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(PageContent);
