import { AUTHENTICATE, DEAUTHENTICATE, USER, LOGGEDIN } from "../types";
import { setCookie, removeCookie } from "../../utilities/cookie";
import axios from "axios";


// gets token from the api and stores it in the redux store and in cookie
export const authenticate = (token) => {
    setCookie("token", token);
    return {
        type: AUTHENTICATE,
        payload: token,
    };
};


export const getUser = (data) => {
    setCookie("user", JSON.stringify(data));
    return {
        type: USER,
        payload: data
    };
};

export const isLoggedIn = (data) => {
    return {
        type: LOGGEDIN,
        payload: data
    };
};

// removing the token
export const deauthenticate = () => {
    removeCookie("token");
    return {
        type: DEAUTHENTICATE
    };
};

// gets the token from the cookie and saves it in the store
const reauthenticate = (token) => {
    return (dispatch) => {
        dispatch({ type: AUTHENTICATE, payload: token });
    };
};

const getUserData = ({ token }, type) => {
    return (dispatch) => {
        axios
            .get(`${process.env.REACT_APP_BACK_URL}/${type}`, {
                headers: {
                    Authorization: "bearer " + token,
                },
            })
            .then((response) => {
                dispatch({ type: USER, payload: response.data.data.user });
            })
            .catch((error) => {
                switch (error?.response?.status) {
                    case 401:
                        // history.push("/");
                        break;
                    case 422:
                        // alert(error.response.data.meta.message);
                        break;
                    case 500:
                        alert("Interval server error! Try again!");
                    case 503:
                        // alert(error.response.data.meta.message);
                        // history.push("/");
                        break;
                    default:
                        // alert(error.response.data.meta.message);
                        break;
                }
            });
    };
};

