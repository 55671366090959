import React, { useContext } from 'react';
import ReactToolTip from 'react-tooltip'

import generateContent from '../../utilities/TipContent'
import { LoginContext } from '../../utilities/context'
import { connect } from "react-redux";

//The main sticky left-hand side bar in the app
function Sidebar(props) {
    const {user} = props;
    
    return (
        <>
            <div className="sidebar">
                {props?.children}
            </div>
            <ReactToolTip className='toolTip' id='tooltip' disable={user?.loggedIn} place='right' effect='solid' globalEventOff='click' backgroundColor='var(--tip-content)' getContent={dataTip => generateContent(dataTip)} clickable={true} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(Sidebar);
