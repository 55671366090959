import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./components/sidebar-components/Sidebar.js";
import Logo from "./components/sidebar-components/Logo.js";
import NavList from "./components/sidebar-components/NavList.js";
import NavItem from "./components/sidebar-components/NavItem.js";
import PlayLists from "./components/sidebar-components/PlayLists.js";
import FeaturedPlaylist from "./components/sidebar-components/FeaturedPlaylist.js";
import FeaturedItem from "./components/sidebar-components/FeaturedItem.js";
import OtherPlaylist from "./components/sidebar-components/OtherPlaylist.js";
import Footer from "./components/footer-components/Footer.js";
import Loading from "./components/featured-components/Loading.js";

import Headerbar from "./components/featured-components/Headerbar";
import PageContent from "./components/featured-components/PageContent";

import HistoryNav from "./components/featured-components/HistoryNav";
import UserPrompt from "./components/featured-components/UserPrompt";
import SearchBar from "./components/featured-components/SearchBar";
import UserInfo from "./components/featured-components/UserInfo";
import CollectionNav from "./components/featured-components/CollectionNav";
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import 'react-jinke-music-player/lib/styles/index.less';

import {
  UserContext,
  LoginContext,
  TokenContext,
  MessageContext,
  PlayContext,
  PlayListContext,
} from "./utilities/context";
import Login from "./components/pages-components/Login.js";
import SignUp from "./components/pages-components/SignUp.js";
import { APIRequest, API_FETCH_PLAYLIST } from "./api";
import { PLAYINDEX, PLAYLIST, ISPLAY, PLAYID } from "./redux/types.js";
import Song from './images/temp.mp3';

function App(props) {
  const { user, playlist } = props;
  const [audioInstance, setAudioInstance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setloggedIn] = useState(user?.loggedIn || false);
  const [token, setToken] = useState(null);
  const [userInfo, setuserInfo] = useState({});
  const [playlists, setPlaylists] = useState([]);
  const [query, setQuery] = useState("");
  const [loginPage, setLoginPage] = useState(false);
  const [signupPage, setSignupPage] = useState(false);

  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [playIndex, setPlayIndex] = useState(null);

  const timerRef = useRef(null);
  const history = useHistory();


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    const pathName = window.location.pathname;
    if (pathName === "/login") {
      if (user?.loggedIn) {
        window.location.href = "/";
      }
      setLoginPage(true);
    } else if (pathName === "/signup") {
      if (user?.loggedIn) {
        window.location.href = "/";
      }
      setSignupPage(true);
    }

    setPlayIndex(playlist?.playIndex)
  }, []);

  useEffect(() => {
    setloggedIn(user?.loggedIn);
    setToken(user?.token);
    setuserInfo(user?.user);
  }, [user]);

  useEffect(() => {
    if (user?.loggedIn) {
      playlistData();
    }
  }, []);

  const playlistData = () => {
    new APIRequest.Builder()
      .get()
      .setReqId(API_FETCH_PLAYLIST)
      .setHeader(user?.token)
      .reqURL(`/elamusic/playlist`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case API_FETCH_PLAYLIST:
        setLoading(false);
        setPlaylists(response?.data?.playlist);
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case API_FETCH_PLAYLIST:
        console.log(response, "error");
        setLoading(false);
        break;
      default:
        break;
    }
  };

  const refreshPlaylist = () => {
    playlistData();
  };

  const setStatusMessage = (message) => {
    // clearTimeout(timerRef.current)
    // setStatus(true)
    // setMessage(message)
    // timerRef.current = setTimeout(() => {
    //   setStatus(false)
    // }, 3000)
  };


  const updatePlayer = (value, playIndex, playId) => {
    let audio = value && value.map(e => {
      return {
        'name': e?.title,
        'cover': e?.images[0]?.url,
        'singer': `${e?.master_artist?.artist?.firstname} ${e?.master_artist?.artist?.lastname}`,
        // 'musicSrc': 'http://res.cloudinary.com/alick/video/upload/v1502689683/Luis_Fonsi_-_Despacito_ft._Daddy_Yankee_uyvqw9.mp3',
        'musicSrc': Song,
      }
    })

    let id;
    if (playId) {
      id = playId
    } else {
      id = (value && value[0]) ? value[0]?.id : null
    }

    // let findIndex = value && value.findIndex(e => e.id === id)
    setPlayIndex(playIndex);

    props.dispatch({ type: PLAYLIST, payload: audio });
    props.dispatch({ type: ISPLAY, payload: true });
    props.dispatch({ type: PLAYINDEX, payload: playIndex });
    props.dispatch({ type: PLAYID, payload: id });

    audioInstance.play();
  }

  const resetQuery = () => {
    setQuery("");
  };

  const darkStyle = {
    backgroundColor: "transparent",
    color: "#fff",
  };

  const lightStyle = {
    backgroundColor: "#fff",
    color: "#181818",
  };

  const options = {
    className: "custom-player",
    glassBg: true,
    showThemeSwitch: false,
    showLyric: false,
    showDestroy: false,
    remove: false,
    toggleMode: false,
    showReload: false,
    showDownload: false,
    showPlayMode: false,
    mode: 'full',
    preload: false,
    showMediaSession: true,
    spaceBar: true,
    autoPlay: false,
    volumeFade: {
      fadeIn: 1000,
      fadeOut: 1000,
    },
  }

  return (
    <>
      {!signupPage && !loginPage ? (
        <div className="App">
          {loading ? (
            <Loading type="app" />
          ) : (
            <MessageContext.Provider value={setStatusMessage}>
              <PlayListContext.Provider value={playlists}>
                <LoginContext.Provider value={loggedIn}>
                  <Sidebar>
                    <Logo />
                    <NavList>
                      <NavItem to="/" exact={true} name="Home" label="Home" />
                      <NavItem
                        to="/search"
                        exact={true}
                        name="Search"
                        label="Search"
                        data_tip="search"
                        data_for="tooltip"
                        data_event="click"
                        style={{ pointerEvents: loggedIn ? "auto" : "none" }}
                      />
                      <NavItem
                        to="/collection/playlist"
                        exact={true}
                        name="Library"
                        label="Your Library"
                        data_tip="library"
                        data_for="tooltip"
                        data_event="click"
                        style={{ pointerEvents: loggedIn ? "auto" : "none" }}
                      />
                    </NavList>
                    <PlayLists
                      top={
                        <FeaturedPlaylist
                          refreshPlaylist={() => refreshPlaylist()}
                        >
                          <FeaturedItem
                            label="Liked Songs"
                            loggedIn={loggedIn}
                          />
                        </FeaturedPlaylist>
                      }
                      bottom={
                        loggedIn ? (
                          <OtherPlaylist playlists={playlists} />
                        ) : null
                      }
                    />
                  </Sidebar>

                  <PlayContext.Provider value={updatePlayer}>
                    <TokenContext.Provider value={token}>
                      <UserContext.Provider value={userInfo}>
                        <div className="featured">
                          <Headerbar>
                            <HistoryNav />

                            <Route exact path="/search">
                              <SearchBar
                                query={query}
                                setQuery={setQuery}
                                resetQuery={resetQuery}
                              />
                            </Route>

                            <Route path="/collection">
                              <CollectionNav />
                            </Route>

                            {loggedIn ? (
                              <UserInfo />
                            ) : (
                              <div className="UserPrompt">
                                <button
                                  className="PromptButton no-outline"
                                  name="Sign Up"
                                  style={darkStyle}
                                  onClick={() => {
                                    setSignupPage(true);
                                    setLoginPage(false);
                                    history.push("/signup");
                                  }}
                                >
                                  Sign Up
                                </button>
                                <button
                                  className="PromptButton no-outline"
                                  name="Log In"
                                  style={lightStyle}
                                  onClick={() => {
                                    setLoginPage(true);
                                    setSignupPage(false);
                                    history.push("/login");
                                  }}
                                >
                                  Log In
                                </button>
                              </div>
                            )}
                          </Headerbar>

                          <PageContent
                            query={query}
                            playlists={playlists}
                            refreshPlaylist={() => refreshPlaylist()}
                            message={message}
                            status={status}
                          />
                        </div>
                      </UserContext.Provider>
                    </TokenContext.Provider>
                  </PlayContext.Provider>

                  <Footer>
                    {loggedIn && (
                      <>
                        <ReactJkMusicPlayer {...options}
                          playIndex={playIndex}
                          audioLists={playlist?.playlist}
                          getAudioInstance={(instance) => {
                            setAudioInstance(instance);
                          }}
                          clearPriorAudioLists
                          onAudioPause={() => {
                            props.dispatch({ type: ISPLAY, payload: false });
                          }}
                          onAudioPlay={() => {
                            props.dispatch({ type: ISPLAY, payload: true });
                          }}
                          onAudioPlay={(value) => {
                            props.dispatch({ type: PLAYINDEX, payload: value?.playIndex });
                          }}
                        />
                      </>
                    )}
                  </Footer>

                  {/* <Player token={token} ref={playerRef} /> */}
                </LoginContext.Provider>
              </PlayListContext.Provider>
            </MessageContext.Provider>
          )}
        </div>
      ) : (
        <>
          <Route path={`/signup`}>
            <SignUp />
          </Route>
          <Route path={`/login`}>
            <Login />
          </Route>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    playlist: state.playlist
  };
};

export default connect(mapStateToProps)(withRouter(App));
