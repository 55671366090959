import React, { useContext, useState } from 'react'
import Icon from '../icons'
import { PlayContext } from '../../utilities/context'
import { Col, Row, Form, Button, Modal, Dropdown } from 'react-bootstrap'
import { connect } from "react-redux";
import { APIRequest, API_DELETE_PLAYLIST, API_EDIT_PLAYLIST, API_FOLLOW_ARTIST, API_LIKE_ALBUM } from "../../api";
import { Notification } from '../notification';
import { useHistory } from 'react-router-dom'


function PlayListFunctions(props) {
    const { type, follow, setMessage, playlistData, user, refetchData, id, setFollow, refreshPlaylist, tracks } = props

    const history = useHistory();

    const [deleteVisible, setDeleteVisible] = useState(false)
    const [editVisible, setEditVisible] = useState(false)

    const handleClose = () => {
        setDeleteVisible(false);
        setEditVisible(false)
    }

    const editPlaylist = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries());

        new APIRequest.Builder()
            .put()
            .setReqId(API_EDIT_PLAYLIST)
            .setHeader(user?.token)
            .jsonParams(formDataObj)
            .reqURL(`/elamusic/playlist/${playlistData?.id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const deletePlaylist = () => {
        new APIRequest.Builder()
            .delete()
            .setReqId(API_DELETE_PLAYLIST)
            .setHeader(user?.token)
            .reqURL(`/elamusic/playlist/${playlistData?.id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_DELETE_PLAYLIST:
                setDeleteVisible(false);
                refetchData();
                refreshPlaylist();
                Notification("success", response?.meta?.message);
                history.push('/collection/playlist')
                break;
            case API_EDIT_PLAYLIST:
                setEditVisible(false);
                refetchData();
                refreshPlaylist();
                Notification("success", response?.meta?.message);
                break;
            case API_FOLLOW_ARTIST:
                setFollow && setFollow(response?.data?.following);
                refetchData();
                break;
            case API_LIKE_ALBUM:
                refetchData();
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_DELETE_PLAYLIST:
                console.log(response, "error");
                break;
            case API_EDIT_PLAYLIST:
                console.log(response, "error");
                Notification("danger", response?.meta?.message);
                break;
            case API_FOLLOW_ARTIST:
                console.log(response, "error");
                break;
            case API_LIKE_ALBUM:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };

    const followArtist = () => {
        new APIRequest.Builder()
            .get()
            .setReqId(API_FOLLOW_ARTIST)
            .setHeader(user?.token)
            .reqURL(`/elamusic/artist/${id}/follow`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const likeAlbum = () => {
        new APIRequest.Builder()
            .post()
            .setReqId(API_LIKE_ALBUM)
            .setHeader(user?.token)
            .jsonParams({
                "id": id,
                "type": 3
            })
            .reqURL(`/elamusic/like/playlist-album-track`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }


    switch (type) {
        case 'playOnly':
            return (
                <div className="playListFunctions">
                    <PlayButtonLarge loggedIn={user?.loggedIn} tracks={tracks} />
                </div>
            )
        case 'none':
            return (
                <div className="playListFunctions">
                    <MoreButton onClick={() => setMessage('Oops, it look like I chose not to implement this feature :)')} />
                </div>
            )
        case 'artist':
            return (
                <div className="playListFunctions">
                    <PlayButtonLarge loggedIn={user?.loggedIn} tracks={tracks} />
                    <button className="followButton no-outline" onClick={() => followArtist()}>{follow ? 'following' : 'follow'}</button>
                </div>
            )
        case 'playlist':
            return (
                <>
                    <div className="playListFunctions">
                        <PlayButtonLarge loggedIn={user?.loggedIn} tracks={tracks} />
                        <MoreButton type={type} setDeleteVisible={setDeleteVisible} setEditVisible={setEditVisible} />
                    </div>

                    <Modal show={deleteVisible} onHide={handleClose} centered backdrop={false} contentClassName="bg_theme_color" className="bg_black close_btn">
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: '18px', color: '#222326' }}>
                                Delete {playlistData && playlistData?.title}?
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg={12} style={{ fontSize: '15px', color: '#222326' }}>
                                    <div>This action cannot be undone.</div>
                                </Col>
                                <Col lg={12}>
                                    <div className="d-flex justify-content-end">
                                        <Button type="submit" style={{ marginTop: '20px' }} className="bg-btn border-0" onClick={() => setDeleteVisible(false)}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" style={{ marginTop: '20px' }} className="bg-btn border-0 ml-3" onClick={() => deletePlaylist()}>
                                            Submit
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>

                    <Modal show={editVisible} onHide={handleClose} centered backdrop={false} contentClassName="bg_theme_color" className="bg_black close_btn">
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: '18px', color: '#222326' }}>
                                Edit Playlist
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={editPlaylist}>
                                <Row>
                                    <Col lg={12}>
                                        <Form.Group>
                                            <Form.Label className="input_label" style={{ color: 'black', fontWeight: '300' }}>Playlist Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Playlist Name" name="title" required defaultValue={playlistData?.title} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Button type="submit" block className="bg-btn border-0 mt-1">
                                            Edit Playlist
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </>
            )
        default:
            return (
                <div className="playListFunctions">
                    <PlayButtonLarge loggedIn={user?.loggedIn} tracks={tracks} />
                    <LikeButton follow={follow} loggedIn={user?.loggedIn} albumLike={() => likeAlbum()} />
                    <MoreButton type={type} />
                </div>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        playlist: state.playlist
    };
};

export default connect(mapStateToProps)(PlayListFunctions);

function PlayButtonLarge({ loggedIn, tracks }) {
    const setPlay = useContext(PlayContext)
    if (loggedIn) {
        return (
            <button className="playButton no-outline" title="Play" onClick={() => {
                setPlay(tracks, 0);
            }}>
                <Icon name="Play" height='28' width='28' />
            </button>
        )
    } else {
        return (
            <button className="playButton no-outline" title="Play" data-tip='play' data-for='tooltipMain' data-event='click' >
                <Icon name="Play" height='28' width='28' />
            </button>
        )
    }

}

function LikeButton({ follow, loggedIn, albumLike }) {
    if (loggedIn) {
        return (
            <button className={`likeButton ${follow ? 'noHover' : ''} no-outline`} style={{ color: follow ? 'var(--tip-content)' : null }} title={follow ? 'Remove from Library' : "Save to Your Library"} onClick={() => albumLike()}>
                <Icon name='Heart' fill={follow} />
            </button>
        )
    } else {
        return (
            <button className="likeButton no-outline" title="Save to Your Library" data-tip='like' data-for='tooltipMain' data-event='click'>
                <Icon name='Heart' fill={follow} />
            </button>
        )
    }
}

function MoreButton(props) {
    const { type, setDeleteVisible, setEditVisible } = props;
    return (
        type !== "album" && <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="playlist_dropdown">
                • • •
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu_item">
                {
                    type === "playlist" &&
                    <Dropdown.Item onClick={() => setEditVisible(true)}>Edit Playlist</Dropdown.Item>
                }
                {/* <Dropdown.Item>Download</Dropdown.Item> */}
                {
                    type === 'playlist' &&
                    <Dropdown.Item onClick={() => {
                        setDeleteVisible(true)
                    }}>Delete Playlist</Dropdown.Item>
                }
                {/* <Dropdown.Item>Share</Dropdown.Item> */}
            </Dropdown.Menu>
        </Dropdown>
    )
}