import React from 'react'
import Icon from '../icons'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function PageBanner({ pageTitle, bannerInfo }) {
    const { name, description, user, followers, primary_color, images, release_date, total } = bannerInfo

    let imgUrl
    if (images && images.length > 0) {
        imgUrl = images[0].url
    }

    return (
        <div className="banner" style={{ backgroundColor: `${primary_color}`, height: pageTitle === 'artist' ? '40vh' : '30vh' }}>
            <div className={`bannerImgDiv ${pageTitle === 'profile' || pageTitle === 'artist' ? 'circleDiv' : null}`}>
                {imgUrl ?
                    <img loading="lazy" src={imgUrl} className={`bannerImg ${pageTitle === 'profile' || pageTitle === 'artist' ? 'circleDiv' : null}`} alt="" /> :
                    <div className="svgSizing">
                        <Icon name='Music2' />
                    </div>
                }
            </div>

            <div className="bannerInfo">
                <h2 className="pageTitle">{pageTitle}</h2>
                <span style={spanStyle}>
                    <h1 className={name && name.length > 15 ? "bannerTitleXL" : "bannerTitle"}>{name}</h1>
                </span>
                <p className="bannerDescription" style={{ display: description === '' ? 'none' : 'flex' }}>{description}</p>
                <div className="additionalInfo">
                    {
                        pageTitle === "playlist" ?
                            user && <span>{`${user?.name}`}</span> : user &&
                            <Link to={`/${user?.type?.toLowerCase()}/${user.id}`} style={{ marginBottom: '8px' }}>{user?.type?.toLowerCase() === 'artist' ? `${user?.firstname} ${user?.lastname}` : ``}</Link>
                    }

                    {total !== 0 && total &&
                        <h2>{total} Playlists</h2>
                    }
                    {
                        pageTitle === 'artist' &&
                        <h2 style={followerStyle}>{followers} {followerTitle(pageTitle)}</h2>
                    }
                    {release_date &&
                        <h2>{moment(release_date).format('MMM Do YYYY')}</h2>
                    }
                </div>
            </div>
            <div className="bannerOverlay"></div>
        </div>
    )
}


function followerTitle(title) {
    switch (title) {
        case 'profile':
            return 'Followers'
        case 'artist':
            return 'Followers'
        default:
            return 'Likes'
    }
}

const followerStyle = {
    fontSize: '16px',
    // lineHeight: '2',
    // marginTop: '4px',
    color: '#fff'
}

const spanStyle = {
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    marginTop: '4px',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textTransform: 'capitalize'
}