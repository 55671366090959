import React from 'react';
import { useEffect, useState, useContext } from 'react'
import axios from 'axios'

import PageBanner from '../featured-components/PageBanner'
import PlayListFunctions from '../featured-components/PlayListFunctions'
import TrackList from '../featured-components/TrackList'

import { UserContext } from '../../utilities/context'
import useTokenScroll from '../../utilities/hooks/useTokenScroll'
import { connect } from "react-redux";
import { APIRequest, API_FETCH_LIKE_PAGE } from "../../api";
import { PLAYLIST } from '../../redux/types';


const LikePage = (props) => {
    const { user } = props;

    const bannerInfo = {
        name: 'Liked Songs',
        description: '',
        user: user?.user,
        followers: 0,
        primary_color: 'rgb(70, 62, 118)',
        images: [{ url: 'https://t.scdn.co/images/3099b3803ad9496896c43f22fe9be8c4.png' }],
    }
    const [tracks, setTracks] = useState([])
    const source = axios.CancelToken.source()
    const [setNext, lastRef] = useTokenScroll(setTracks, user?.token, source)

    useEffect(() => {
        refreshLikePage();
    }, [])

    const refreshLikePage = () => {
        new APIRequest.Builder()
            .get()
            .setReqId(API_FETCH_LIKE_PAGE)
            .setHeader(user?.token)
            .reqURL(`/elamusic/user/tracks`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_LIKE_PAGE:
                setTracks(response?.data?.likeTrack);
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_LIKE_PAGE:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };


    return (
        <div className='listPage'>
            <PageBanner pageTitle='playlist' bannerInfo={bannerInfo} />
            <div className="playListContent">
                <div className="playListOverlay" style={{ backgroundColor: `${bannerInfo.primary_color}` }}></div>
                <PlayListFunctions type='playOnly' tracks={tracks}/>
                <div className="page-content">
                    <TrackList ref={lastRef} tracks={tracks} type="liked" refetchData={() => refreshLikePage()} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        playlist: state.playlist
    };
};

export default connect(mapStateToProps)(LikePage);


