import React from 'react';
import { store } from "react-notifications-component";

export const Notification = (type, message) => {
    return store.addNotification({
        message: <div className="text_large">{message}</div>,
        type: type,
        insert: "top",
        container: "top-right",
        dismiss: {
            duration: 2500,
            // onScreen: true,
            pauseOnHover: true,
            waitForAnimation: false,
        },
    });
};
