import React, { useState, useEffect } from 'react'

import BrowseCard from '../featured-components/BrowseCard'
import PageTitle from '../featured-components/PageTitle'
import { connect } from "react-redux";
import { APIRequest, API_FETCH_GENRE_PAGE } from "../../api";

function BrowsePage(props) {
    const { user } = props;
    const [genre, setGenre] = useState([])

    useEffect(() => {
        new APIRequest.Builder()
            .get()
            .setReqId(API_FETCH_GENRE_PAGE)
            .setHeader(user?.token)
            .reqURL(`/elamusic/genre`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }, [])

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_GENRE_PAGE:
                setGenre(response?.data?.data);
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_GENRE_PAGE:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };


    return (
        <div className="page-content">
            <div className='browsePage'>
                <PageTitle name='Browse All' />
                <div className="browseGrid">
                    {genre && genre.map((genre) => {
                        return <BrowseCard key={genre?.id} info={genre} />
                    })}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(BrowsePage);
