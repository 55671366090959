import axios from "axios";

// const API = 'http://localhost:8000/api/v2/'
const API = 'https://m.webmobtech.biz/api/v2/'

let axiosInstance = axios.create({
  baseURL: `${API}/`,
  timeout: 20000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    let dataResponse = {
      status: response.status,
      meta: response.data.meta,
      data: response.data.data !== undefined ? response.data.data : null,
    };
    return Promise.resolve(dataResponse);
  },
  (error) => {
    let errorResponse = {
      status: error.response !== undefined ? error.response.status : 500,
      meta:
        error.response.data.meta !== undefined
          ? error.response.data.meta
          : undefined,
      data:
        error.response.data.data !== undefined
          ? error.response.data.data
          : undefined,
    };

    switch (errorResponse.status) {
      case 404:
        break;
      case 500:
        break;
      case 503:

        break;
      case 400:

        break;
      default:
        break;
    }
    return Promise.reject(errorResponse);
  }
);

export {
  axiosInstance
};
