import React, { useContext, useEffect, useState } from 'react'
import { MessageContext } from '../../utilities/context'
import CollectionRow from '../featured-components/CollectionRow'
import { APIRequest, API_FETCH_HOME_PAGE } from '../../api';
import { connect } from "react-redux";

function HomePage(props) {
    const { user } = props;
    const setMessage = useContext(MessageContext)
    const [collections, setCollections] = useState([])

    useEffect(() => {
        new APIRequest.Builder()
            .get()
            .setReqId(API_FETCH_HOME_PAGE)
            .setHeader(user?.token)
            .reqURL("/elamusic/feed")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }, [])

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_HOME_PAGE:
                let data = response?.data;

                if (data?.banners) {
                    delete data?.banners
                }

                if (data?.playlist) {
                    delete data?.playlist
                }

                setCollections(response?.data)
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_HOME_PAGE:
                console.log(response, "error");
                break;
            default:
                break;
        }
    }

    return (
        <div className="page-content">
            <div className='pageContent'>
                {
                    Object.entries(collections).map(([name, info]) => {
                        return <CollectionRow name={name} key={name} id={info[0]?.type?.toLowerCase()} playlists={info} isShow={true} />
                    })
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(HomePage);