import React, { useEffect, useState } from 'react'
import SearchRow from '../featured-components/SearchRow'
import { connect } from "react-redux";
import { APIRequest, API_SEARCH_PAGE } from "../../api";

function QueryPage(props) {
    const { query, user } = props;
    const [album, setAlbum] = useState([])
    const [artist, setArtist] = useState([])
    const [track, setTrack] = useState([])
    const [playlist, setPlaylist] = useState([])

    useEffect(() => {
        new APIRequest.Builder()
            .post()
            .setReqId(API_SEARCH_PAGE)
            .setHeader(user?.token)
            .jsonParams({
                value: query
            })
            .reqURL(`/elamusic/globalSearch`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }, [query])

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_SEARCH_PAGE:
                setAlbum(response?.data?.albums);
                setArtist(response?.data?.artists);
                setTrack(response?.data?.singles);
                setPlaylist(response?.data?.playlist);
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_SEARCH_PAGE:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };

    return (
        <div className="page-content">
            <div className='pageContent'>
                <SearchRow title='Artists' type='Artist' query={query} result={artist} />
                <SearchRow title='Albums' type='Album' query={query} result={album} />
                <SearchRow title='Playlists' type='Playlist' query={query} result={playlist} />
                <SearchRow title='Singles' type='Track' query={query} result={track} />
            </div>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(QueryPage);
