import { ISPLAY, PLAYLIST, PLAYINDEX, PLAYID } from "../types";

const initialState = {
  isPlay: false,
  playlist: [],
  playIndex: 0,
  playId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ISPLAY:
      return Object.assign({}, state, { isPlay: action.payload });
    case PLAYLIST:
      return Object.assign({}, state, { playlist: action.payload });
    case PLAYINDEX:
      return Object.assign({}, state, { playIndex: action.payload });
    case PLAYID:
      return Object.assign({}, state, { playId: action.payload });
    default:
      return state;
  }
};
