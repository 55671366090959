import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import './index.less';
import { Provider } from "react-redux";
import { createStore } from "redux";
import allReducer from "./redux/reducers";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { createBrowserHistory } from 'history';


import { BrowserRouter as Router } from 'react-router-dom'

const loadState = () => {
  try { // It's also possible to use other local storage if it doesn't support localStorage
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    // ...error handling
    return undefined;
  }
}
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // ...error handling
  }
};

const store = createStore(
  allReducer,
  loadState(),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
  saveState(store.getState());
});

const history = createBrowserHistory();



ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ReactNotification />
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);


