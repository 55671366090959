import React from 'react';
import ListItem from './ListItem.js'

function OtherPlaylist({ playlists }) {
    return (
        <div className="other-playlist-container">
            <ul className="other-list">
                {playlists && playlists.map((playlist) => <ListItem key={playlist?.id} name={playlist?.title} id={playlist?.id} />)}
            </ul>
        </div>
    );
}

export default OtherPlaylist;
