import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Button, InputGroup, FormControl, Modal } from 'react-bootstrap'
import User from '../../images/User_placeholder.svg'
import { connect } from "react-redux";
import { APIRequest, API_FETCH_PROFILE, API_PROFILE_SUBMIT, API_CHANGE_PASSWORD_SUBMIT, API_CHANGE_PROFILE, API_GET_COUNTRY, API_GET_CITY } from '../../api';
import { Notification } from '../notification'
import { getUser } from '../../redux/actions/authAction';

function Profile(props) {
    const { user } = props;
    const [validated, setValidated] = useState(false);
    const [visible, setVisible] = useState(false);
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [inputValue, setInputValue] = useState({});
    const [errors, setErrors] = useState({});
    const [userData, setUserData] = useState(null);
    const [country, setCountry] = useState([]);
    const [city, setCity] = useState([]);
    const [userCountry, setUserCountry] = useState(null);
    const [userCity, setUserCity] = useState(null);

    const handleChangeInput = (event) => {
        let input = inputValue;
        input[event.target.name] = event.target.value;
        setInputValue(input);
    }

    useEffect(() => {
        setImage({
            preview: user?.user?.profile_pic
        });

        new APIRequest.Builder()
            .get()
            .setReqId(API_FETCH_PROFILE)
            .setHeader(user?.token)
            .reqURL("/fetch_profile")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();

        new APIRequest.Builder()
            .get()
            .setReqId(API_GET_COUNTRY)
            .reqURL("/content_list")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();

        cityFetchData();
    }, [])

    const cityFetchData = (id) => {

        let countryId;
        if (id) {
            countryId = id;
        } else {
            countryId = user?.user?.country_id;
        }

        new APIRequest.Builder()
            .post()
            .setReqId(API_GET_CITY)
            .jsonParams({
                "country_id": countryId
            })
            .reqURL("/city")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const pwdValidate = () => {
        let input = inputValue;
        let errors = {};
        let isValid = true;

        if (!input["pwd"]) {
            isValid = false;
            errors["pwd"] = "Please enter your password.";
        }

        if (!input["confirm_pwd"]) {
            isValid = false;
            errors["confirm_pwd"] = "Please enter your confirm password.";
        }

        if (!input["current_pwd"]) {
            isValid = false;
            errors["current_pwd"] = "Please enter your current password.";
        }

        if (typeof input["pwd"] !== "undefined" && typeof input["confirm_pwd"] !== "undefined") {
            if (input["pwd"] != input["confirm_pwd"]) {
                isValid = false;
                errors["confirm_pwd"] = "Password not match.";
            }
        }

        setErrors(errors);
        return isValid;
    }

    const onSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            const formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries());

            let data = {
                name: formDataObj?.name,
                email: formDataObj?.email,
                age: formDataObj?.age,
                country_id: parseInt(formDataObj?.country),
                city_id: parseInt(formDataObj?.city),
            }

            new APIRequest.Builder()
                .post()
                .setReqId(API_PROFILE_SUBMIT)
                .jsonParams(data)
                .setHeader(user?.token)
                .reqURL("/update_profile")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        }
        setValidated(true);
    }

    const handleChange = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
            const formData = new FormData();
            formData.append("profile_pic", e.target.files[0]);

            new APIRequest.Builder()
                .post()
                .setReqId(API_CHANGE_PROFILE)
                .jsonParams(formData)
                .setHeader(user?.token)
                .reqURL("/update_profile_pic")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        }
    }

    const handleClose = () => {
        setVisible(false);
        setErrors({})
    }

    const passwordSubmit = (event) => {
        event.preventDefault();
        if (pwdValidate()) {
            const formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries());

            new APIRequest.Builder()
                .post()
                .setReqId(API_CHANGE_PASSWORD_SUBMIT)
                .jsonParams(formDataObj)
                .setHeader(user?.token)
                .reqURL("/change_password")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        }
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_PROFILE:
                setUserData(response?.data?.user);
                setUserCity(response?.data?.user?.city?.id);
                setUserCountry(response?.data?.user?.country?.id);
                break;
            case API_PROFILE_SUBMIT:
                props.getUser(response?.data?.user);
                Notification("success", response?.meta?.message);
                break;
            case API_CHANGE_PASSWORD_SUBMIT:
                setVisible(false);
                Notification("success", response?.meta?.message);
                break;
            case API_CHANGE_PROFILE:
                props.getUser(response?.data?.user);
                Notification("success", response?.meta?.message);
                break;
            case API_GET_COUNTRY:
                setCountry(response?.data?.country);
                break;
            case API_GET_CITY:
                setCity(response?.data?.city);
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_PROFILE:
                Notification("danger", response?.meta?.message);
                break;
            case API_PROFILE_SUBMIT:
                Notification("danger", response?.meta?.message);
                break;
            case API_CHANGE_PASSWORD_SUBMIT:
                Notification("danger", response?.meta?.message);
                break;
            case API_CHANGE_PROFILE:
                Notification("danger", response?.meta?.message);
                break;
            case API_GET_COUNTRY:
                console.log(response, "error");
                break;
            case API_GET_CITY:
                console.log(response, "error");
                break;
            default:
                break;
        }
    }

    const handleCountry = (e) => {
        setUserCountry(e.target.value);
        cityFetchData(e.target.value);
    }

    return (
        <div className="page-content">
            <div className="RowTitle">
                <h1 style={{
                    fontSize: '24px',
                    lineHeight: '28px',
                    letterSpacing: '-0.04em',
                    fontWeight: '700',
                    color: 'white'
                }}>Profile</h1>
            </div>
            <Row className="d-flex justify-content-center mt-lg-5 mt-3 py-5 px-3 px-lg-0 py-4 card_shadow m-0">
                <Col lg={3} md={8} xs={8} className="px-lg-5 px-1">
                    <div className="d-flex justify-content-center">
                        {
                            image.preview ?
                                <>
                                    <img
                                        src={image.preview}
                                        alt="user pic"
                                        height="120px"
                                        width="120px"
                                        style={{ borderRadius: '50%' }}
                                    />
                                </>
                                :
                                <div
                                    style={{
                                        height: "120px",
                                        width: "120px",
                                        objectFit: "contain",
                                    }}
                                >
                                    <img
                                        className="thumbnail-image"
                                        src={User}
                                        alt="user pic"
                                        height="100%"
                                        width="100%"
                                        style={{ borderRadius: "10px" }}
                                    />
                                </div>
                        }

                    </div>
                    <Form.Group style={{ width: "auto" }} className="mt-3">
                        <Form.File
                            label="Upload Profile"
                            id="upload-button"
                            className="custom_button custom_file_upload"
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button className="bg-btn border-0 mb-3" block
                        onClick={() => {
                            setVisible(true);
                        }}
                    >
                        Change Password
                    </Button>
                </Col>
                <Col lg={8} className="offset-0">
                    <Form onSubmit={onSubmit} noValidate validated={validated}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <Form.Group>
                                    <Form.Label style={{ color: 'white', fontSize: '17px' }}>Name</Form.Label>
                                    <Form.Control name="name" type="text" placeholder="Name" required style={{ borderRadius: '5px' }} defaultValue={userData?.name} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Name
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Label style={{ color: 'white', fontSize: '17px' }}>Email</Form.Label>
                                <InputGroup>
                                    <Form.Control name="email" type="email" placeholder="Email" required style={{ borderRadius: '5px' }} defaultValue={userData?.email} disabled />

                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid Email
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group className="mt-lg-0 mt-3">
                                    <Form.Label style={{ color: 'white', fontSize: '17px' }}>Country</Form.Label>
                                    <Form.Control name="country" as="select" placeholder="Select Country" required style={{ borderRadius: '5px' }} value={userCountry} onChange={handleCountry}>
                                        <option value="" style={{ display: 'none' }}>Country</option>
                                        {
                                            (country && country.length > 0) && country.map(e => <option value={e?.id}>{e?.name}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Country is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group>
                                    <Form.Label style={{ color: 'white', fontSize: '17px' }}>City</Form.Label>
                                    <Form.Control name="city" as="select" placeholder="Select City" required style={{ borderRadius: '5px' }} value={userCity} onChange={(e) => setUserCity(e.target.value)}>
                                        <option value="" style={{ display: 'none' }}>City</option>
                                        {
                                            (city && city.length > 0) && city.map(e => <option value={e?.id}>{e?.name}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        City is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12}>
                                <Form.Group className="mt-lg-0 mt-3">
                                    <Form.Label style={{ color: 'white' }}>Age</Form.Label>
                                    <Form.Control name="age" type="text" placeholder="Age (e.g.:35)" required style={{ borderRadius: '5px' }} defaultValue={userData?.age} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter Age
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12} sm={12}>
                                <Button type="submit" className="bg-btn mt-3 border-0 float-right">
                                    Update My Profile
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Modal show={visible} onHide={handleClose} centered backdrop={false} contentClassName="bg_theme_color" className="bg_black close_btn">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '18px', color: '#222326' }}>
                        Change Password
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={passwordSubmit}>
                        <Row>
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label className="input_label" style={{ color: 'black', fontWeight: '300' }}>Current Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter Current Password" name="current_pwd" onChange={handleChangeInput} />
                                    <div className="text-danger text_small">{errors.current_pwd}</div>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label className="input_label" style={{ color: 'black', fontWeight: '300' }}>New Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter Password" name="pwd" onChange={handleChangeInput} />
                                    <div className="text-danger text_small">{errors.pwd}</div>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label className="input_label" style={{ color: 'black', fontWeight: '300' }}>Confirm Password</Form.Label>
                                    <Form.Control type="password" placeholder="Confirm Password" name="confirm_pwd" onChange={handleChangeInput} />
                                    <div className="text-danger text_small">{errors.confirm_pwd}</div>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Button type="submit" block className="bg-btn border-0 mt-1">
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => ({
    getUser: (user) => dispatch(getUser(user))
})


export default connect(mapStateToProps, mapDispatchToProps)(Profile);
