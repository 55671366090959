import React, { useEffect, useState } from 'react'
import PlayCard from './PlayCard'

const RowGrid = React.forwardRef(({ playlists, type }, ref) => {
    const [grid, setGrid] = useState(6);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    });

    const handleResize = () => {
        let width = window.innerWidth;
        if (width >= 1920) {
            setGrid(9)
        } else if (width === 1024) {
            setGrid(4)
        } else {
            setGrid(6)
        }
    };

    return (
        <div className="RowGrid">
            {
                type === "full" ? playlists && playlists?.map((playlist, index) => {
                    return <PlayCard ref={ref} key={playlist.id} id={playlist?.id} info={playlist} type={playlist?.type} index={index}/>
                }) :
                    playlists && playlists.slice(0, grid).map((playlist, index) => {
                        return <PlayCard ref={ref} key={playlist.id} id={playlist?.id} info={playlist} type={playlist?.type} index={index}/>
                    })
            }
        </div>
    )
})

export default RowGrid
