import React from 'react';

const Repeat = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
            x="0px" 
            y="0px" 
            viewBox="0 0 511.991 511.991" 
            space="preserve">
            <g>
                <path 
                    fill="currentColor"
                    d="M465.45,279.263c-12.87,0-23.273,10.426-23.273,23.273c0,38.493-31.325,69.818-69.818,69.818H186.177v-69.818     c0-8.937-5.143-17.105-13.172-20.969c-8.122-3.863-17.641-2.793-24.646,2.793L31.995,377.451     c-5.516,4.422-8.727,11.101-8.727,18.176c0,7.075,3.212,13.754,8.727,18.176l116.364,93.091     c4.212,3.351,9.356,5.097,14.545,5.097c3.421,0,6.889-0.768,10.1-2.304c8.029-3.863,13.172-12.032,13.172-20.969V418.9h186.182     c64.163,0,116.364-52.201,116.364-116.364C488.723,289.689,478.32,279.263,465.45,279.263z"/>
                <path 
                    fill="currentColor"
                    d="M46.541,232.718c12.87,0,23.273-10.426,23.273-23.273c0-38.493,31.325-69.818,69.818-69.818h186.182v69.818     c0,8.937,5.143,17.105,13.172,20.969c3.235,1.536,6.679,2.304,10.1,2.304c5.19,0,10.333-1.745,14.545-5.097l116.364-93.091     c5.516-4.422,8.727-11.101,8.727-18.176s-3.212-13.754-8.727-18.176L363.632,5.087c-6.982-5.585-16.617-6.656-24.646-2.793     s-13.172,12.032-13.172,20.969v69.818H139.632c-64.163,0-116.364,52.201-116.364,116.364     C23.268,222.292,33.671,232.718,46.541,232.718z"/>
            </g>

        </svg>
    );
}

export default Repeat;
