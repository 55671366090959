import React from 'react'
import { Link } from 'react-router-dom'

export default function BrowseCard({ info }) {
    const { images, name, id } = info
    const img = images[0].url
    return (
        <div className="browseLinkContainer">
            <Link to={`/genr/${id}`} className="browseLink">
                <h3 style={titleStyle}>{name}</h3>
                <div style={overlayStyle}></div>
                <div style={{ height: '180px', width: 'auto', objectFit: 'cover' }}>
                    <img loading="lazy" src={img} alt="" height="100%" width="100%" />
                </div>
            </Link>
        </div>
    )
}


const titleStyle = {
    fontSize: '24px',
    padding: '16px',
    lineHeight: '1.3em',
    letterSpacing: '-.04em',
    overflowWrap: 'break-word',
    position: 'absolute',
    zIndex: '50',
    bottom: '0',
    textAlign: 'left',
    margin: 'auto',
    hyphens: 'auto'
}

const overlayStyle = {
    background: 'linear-gradient(0deg,rgba(0,0,0,0.5),rgba(0,0,0,0.5))',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%'
}