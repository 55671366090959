import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { TokenContext, LoginContext, MessageContext, PlayContext } from '../../utilities/context'
import { connect } from "react-redux";

import PageBanner from '../featured-components/PageBanner'
import PlayListFunctions from '../featured-components/PlayListFunctions'
import AboutMenu from '../featured-components/AboutMenu'
import Loading from '../featured-components/Loading'
import { APIRequest, API_FETCH_ARTIST_PAGE } from "../../api";
import { PLAYLIST } from '../../redux/types';

function ArtistPage(props) {
    const { user } = props;

    const setMessage = useContext(MessageContext)
    const setPlay = useContext(PlayContext)
    const [loading, setLoading] = useState(true)

    const [bannerInfo, setbannerInfo] = useState({
        name: '',
        description: '',
        user: [],
        followers: 0,
        primary_color: 'rgb(83, 83, 83)',
        images: [],
        total: 0
    })
    const [artistId, setArtistId] = useState(null);

    const [tracks, setTracks] = useState([])
    const [album, setAlbum] = useState([])
    const [follow, setFollow] = useState(false)
    const [uri, setUri] = useState('')

    useEffect(() => {
        refreshArtist();
    }, [])

    const refreshArtist = () => {
        let id = window.location.pathname;
        setArtistId(parseInt(id.replace("/artist/", "")));

        new APIRequest.Builder()
            .get()
            .setReqId(API_FETCH_ARTIST_PAGE)
            .setHeader(user?.token)
            .reqURL(`/elamusic${id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_ARTIST_PAGE:
                setLoading(false);
                setTracks(response?.data?.artist?.artist_tracks);
                setAlbum(response?.data?.artist?.artist_albums);
                setFollow(response?.data?.artist?.is_following);
                setbannerInfo({
                    name: `${response?.data?.artist?.firstname || ''} ${response?.data?.artist?.lastname || ''}`,
                    description: `${response?.data?.artist?.bio || ''}`,
                    user: [],
                    followers: response?.data?.artist?.follow_users_count,
                    primary_color: 'rgb(83, 83, 83)',
                    images: response?.data?.artist?.images,
                    total: 0
                })
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_FETCH_ARTIST_PAGE:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };

    return (
        loading ?
            <Loading />
            :
            <div className='listPage'>
                <PageBanner pageTitle='artist' bannerInfo={bannerInfo} />
                <div className="playListContent">
                    <div className="playListOverlay" style={{ backgroundColor: `${bannerInfo.primary_color}` }}></div>
                    {
                        (tracks && tracks.length > 0) && <PlayListFunctions type='artist' follow={follow} setFollow={setFollow} setMessage={setMessage} id={artistId} refetchData={() => refreshArtist()} tracks={tracks} />
                    }
                    <div className="page-content">
                        <AboutMenu id={artistId} tracks={tracks} album={album} type="artist" title="Popular" refetchData={() => refreshArtist()} />
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        playlist: state.playlist
    };
};


export default connect(mapStateToProps)(ArtistPage);


