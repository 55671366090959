import { combineReducers } from "redux";
import authReducer from "./authReducers";
import playlistReducer from './playListReducer'

const rootReducer = combineReducers({
  user: authReducer,
  playlist: playlistReducer
});

export default rootReducer;
