import React, { useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'

import CardInfo from './CardInfo'
import CardDisplay from './CardDisplay'
import Icon from '../icons'
import { connect } from 'react-redux'
import { APIRequest, API_PLAY_CARD } from "../../api";

import { TokenContext, PlayContext } from '../../utilities/context'

const PlayCard = React.forwardRef((props, ref) => {
    const { info, id, type, user, index } = props;
    const url = () => {
        if (type === 'Track') {
            return '#'
        } if (type === 'Genre') {
            return `/genr/${id}`
        } else {
            return `/${type?.toLowerCase()}/${id}`
        }
    }

    const token = useContext(TokenContext)
    const setPlay = useContext(PlayContext)


    let img = (info?.images && info?.images[0]) ? info?.images[0]?.url : null

    if (info?.tracks_images && info?.tracks_images[0] && info?.tracks_images[0].images[0]) {
        img = info?.tracks_images[0]?.images[0]?.url
    }

    const uri = () => {
        if (type === 'Track') {
            return '/track/singles'
        } if (type === 'Genre') {
            return `/genre/${id}`
        } else {
            return `/${type?.toLowerCase()}/${id}`
        }
    }

    const getData = () => {
        new APIRequest.Builder()
            .get()
            .setReqId(API_PLAY_CARD)
            .setHeader(user?.token)
            .reqURL(`/elamusic${uri()}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case API_PLAY_CARD:
                if (response?.data?.artist?.artist_tracks) {
                    setPlay(response?.data?.artist?.artist_tracks, 0);
                } else if (response?.data?.album?.album_tracks) {
                    setPlay(response?.data?.album?.album_tracks, 0);
                } else if (response?.data?.genre?.singles) {
                    setPlay(response?.data?.genre?.singles, 0);
                } else if (response?.data?.playlist?.track_list) {
                    setPlay(response?.data?.playlist?.track_list, 0)
                } else if (response?.data?.new_release_singles) {
                    setPlay(response?.data?.new_release_singles, index);
                }
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case API_PLAY_CARD:
                console.log(response, "error");
                break;
            default:
                break;
        }
    };

    return (
        <div className='pcWrapper'>
            {
                user?.loggedIn ? <Link to={url()} style={{ textDecoration: 'none', color: 'var(--main-text)', zIndex: '3' }}>
                    <div ref={ref} className="PlayCard">
                        <CardDisplay url={img} type={info?.type} />
                        <CardInfo title={title(info)} />
                    </div>
                </Link> : <div ref={ref} className="PlayCard">
                    <CardDisplay url={img} type={info?.type} />
                    <CardInfo title={title(info)} />
                </div>
            }
            {/* <Link to={url()} style={{ textDecoration: 'none', color: 'var(--main-text)', zIndex: '3' }}>
                <div ref={ref} className="PlayCard">
                    <CardDisplay url={img} type={info?.type} />
                    <CardInfo title={title(info)} />
                </div>
            </Link> */}
            {
                user?.loggedIn ?
                    <button className="smallButton no-outline" title="Play"
                        onClick={() => {
                            getData()
                        }}
                    >
                        <Icon name="Play" height='17' width='17' />
                    </button>
                    :
                    <button className="smallButton no-outline" title="Play" data-tip='play' data-for='tooltipMain' data-event='click'>
                        <Icon name="Play" height='17' width='17' />
                    </button>
            }
        </div>
    )
})


function title(info) {
    switch (info?.type) {
        case 'Artist':
            return `${info?.firstname} ${info?.lastname}`
        case 'Genre':
            return info?.name
        default:
            return info?.title
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(PlayCard);

